.controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
        margin: 0 5px;
    }

    &__video,
    &__switch,
    &__mute,
    &__blur {
        background: #fff;
    }

    &__videooff,
    &__muted,
    &__end {
        background: #ff381b;
    }
}
