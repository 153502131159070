@import 'shared.scss';

@font-face {
    font-family: 'Rubik-Regular';
    src: url('fonts/Rubik-Regular.ttf');
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url('fonts/Rubik-Medium.ttf');
}
@font-face {
    font-family: 'Rubik-Light';
    src: url('fonts/Rubik-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('fonts/Roboto-Medium.ttf');
}

html {
    height: 100%;
}

body {
    margin: 0;
    min-width: 300px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

.box.alt .sa-box-body {
    padding: 0;
}

* {
    box-sizing: border-box;
}

#root {
    height: 100%;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}

.react-resizable-handle-se {
    z-index: 3;
}

p {
    margin: 20px 0;
}

.popup {
    max-width: 90vw;
    font-family: Helvetica;
    color: $capita-blue;
}

canvas,
video {
    position: relative !important;
    object-fit: contain !important;
    max-width: 100%;
    max-height: 100%;
}

canvas {
    transform: rotateY(180deg);
    background: $tile-background;
}
