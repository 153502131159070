.add-participants {
    display: flex;
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .add-participant {
        &:first-child {
            border-right: 1px solid #e6e6e6;
        }
    }
}

.not-connected {
    color: #a0a0a0;
    min-height: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e6e6e6;
}

.add-popup {
    .popup {
        padding: 10px;
        border-radius: 2px;
    }
}
