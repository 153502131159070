@import 'shared.scss';

$video-width: 180px;

.agent-meeting {
    position: relative;
    background: #e6e6e6;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    min-height: 300px;

    &__main {
        position: relative;
        width: 80%;
        display: flex;
        justify-content: center;
        max-width: calc(100% - #{$video-width});
    }

    &__videos {
        min-width: $video-width;
        width: 20%;
        max-height: 100vh;
        overflow-y: auto;

        .tile {
            max-height: 200px;
            display: flex;
        }
    }

    &__add.button {
        background: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #9fabb8;
        min-height: 110px;

        img {
            margin-bottom: 10px;
        }
    }

    &__selected {
        display: flex;
        height: 100%;
        width: 100%;

        .tile {
            display: flex;
        }

        canvas,
        video {
            flex: 1 1 0;
            min-width: 0;
        }
    }

    .controls {
        position: absolute;
        bottom: 10px;
        margin: auto;
        left: 0;
        right: 0;
    }
}
