.add-participant {
    display: flex;
    align-items: flex-end;
    flex: 1;
    padding: 20px;
    flex-direction: column;

    &__add {
        margin-top: 10px;
        padding: 0 20px;
        width: auto;
    }
    &__error {
        margin-top: 10px;
        color: red;
        font-size: 12px;
        align-self: flex-start;
    }
    .sa-dropdown-wrapper,
    .input {
        width: 100%;
        margin: 5px 0;
    }
}
