@import 'shared.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $capita-blue;
    min-height: 50px;
    padding: 0 20px;
}
