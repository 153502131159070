.round-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    &.small {
        width: 20px;
        height: 20px;
    }

    &.large {
        width: 48px;
        height: 48px;
    }
}
