@import 'shared.scss';

.customer-meeting {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: $tile-background;
    flex-direction: column;

    @media (min-width: $break-mobile) {
        flex-direction: row-reverse;
    }

    .controls {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
    }

    &__attendees {
        display: flex;
        align-items: flex-start;

        @media (min-width: $break-mobile) {
            overflow-y: auto;
            min-width: 180px;
            flex-direction: column;
            height: 100%;
            width: 20%;

            .tile {
                max-height: 46%;
                min-height: 120px;
            }
        }

        @media (max-width: $break-mobile) {
            overflow-x: auto;
            overflow-y: hidden;
            height: 25%;
            width: 100%;
            .tile {
                min-width: 46%;
                height: 100%;
                display: flex;
            }
        }

        button {
            background: black;
        }

        canvas,
        video {
            flex: 1 1 0;
            min-width: 0;
            background-color: #000;
            @media (max-width: $break-mobile) {
                max-height: 20vh;
                min-height: 20vh;
            }
            //max-width: 30vw;
        }
    }

    &__selected {
        max-height: 100%;
        height: 100%;
        max-width: 100vw;
        display: flex;
        align-items: center;

        @media (min-width: $break-mobile) {
            width: 80%;
        }

        @media (max-width: $break-mobile) {
            height: 75%;
        }

        .tile {
            height: 100%;
            min-height: 100px;
            max-height: calc(100vh - 50px);
            @media (max-width: $break-mobile) {
                min-width: 100vw;
            }
        }
    }
}
