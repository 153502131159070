@import 'shared.scss';

.tile {
    width: 100%;
    position: relative;

    &__remove.button {
        background: red;
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
    }

    &:hover {
        .tile__remove {
            display: flex;
        }
    }

    &__muted {
        width: 20px;
        height: 20px;
        @include round;
        background: #9fabb8;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    &__name {
        position: absolute;
        bottom: 1px;
        left: 0;
        background: rgba(28, 26, 24, 0.6);
        color: #fff;
        padding: 5px 10px;
        font-size: 14px;
        max-width: calc(100% - 28px);
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .tile-button {
        min-height: 110px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $tile-background;
    }
}
