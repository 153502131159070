@import 'shared.scss';

.no-video {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $tile-background;
    height: 100%;
    width: 100%;
}
