@import 'shared.scss';

.customer {
    position: relative;
    height: 100%;
    width: 100vw;
    color: $capita-blue;
    font-family: Helvetica;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: $tile-background;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius: 5px;
    }
}
