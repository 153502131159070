@import 'shared.scss';

.survey-option {
    width: 100%;
    padding: 12px;
    text-align: left;
    &.selected {
        background: $capita-blue;
        color: #fff;
    }
    background: rgba(1, 10, 64, 0.05);
    color: $capita-blue;
}
