@mixin round {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

$tile-background: #1b1c20;
$capita-blue: #010a40;
$break-mobile: 780px;
