@import 'shared.scss';

.customer-button {
    background: $capita-blue;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;

    &.alt {
        outline: 1px solid $capita-blue;
        background: white;
        color: $capita-blue;
    }
}
